import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

interface iTodo {
  id: number;
  task: string;
  status: Boolean;
  description: string;
}

const TodoList = () => {
  const navigate = useNavigate();
  const [task, setTask] = useState("");
  const [description, setDescription] = useState("");
  const [describe, setDescribe] = useState("");
  const [area, setArea] = useState("");
  const [submitDes, setSubmitdes] = useState("");
  const [edit, setEdit] = useState(-1);
  const [data, setData] = useState<iTodo[]>([
    {
      id: 1,
      task: "Go Jugging",
      status: false,
      description: "Nothing now",
    },
  ]);

  // window.localStorage.setItem("todoList", JSON.stringify(data));

  let rand = Math.floor(Math.random() * 3000) + 4000;

  const addTask = () => {
    setDescribe("jo");

    setData((prev) =>
      [
        ...prev,
        { id: rand, task: task, status: false, description: description },
      ].reverse()
    );
  };
  const showDescribe = () => {
    setSubmitdes("yes");
    setArea("show");
  };
  const removeData = (id: number) => {
    const myFilter = data.filter((el) => el.id !== id);
    setData(myFilter);
  };

  const addName = JSON.parse(window.localStorage.getItem("todoNameUser") || "");
  const showEdit = (id: number) => {
    setEdit(id);
  };

  return (
    <div>
      <Container>
        <br />
        <br />
        <Wrapper>
          <ImageBox />
          <h2>Welcome to mini task manager {addName}</h2>
          <p>
            Here you can organise your daily tasks, assignments and goals...
          </p>
          <InputField
            onChange={(e) => {
              setTask(e.target.value);
            }}
            placeholder="What will you be doing today?"
          />

          {task === "" ? (
            <>
              <Button bc="silver" cs="not-allowed">
                Submit Task
              </Button>
            </>
          ) : (
            <>
              <Button onClick={addTask} bc="black" cs="pointer">
                Submit Task
              </Button>
            </>
          )}
          {describe === "jo" ? (
            <>
              <DescriptionButton onClick={showDescribe}>
                Describe Task?
              </DescriptionButton>
            </>
          ) : null}
          {/* <Describing>
            {area === "show" ? (
              <>
                <HoldDescribe>
                  <DescribeInput
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    placeholder="Describe task"></DescribeInput>
                  <DescriptionButton>Submit</DescriptionButton>
                </HoldDescribe>
              </>
            ) : null}
          </Describing> */}
          <br />
          <br />
          <h3>All Task</h3>
          <CardHold>
            {data?.map((props) => (
              <Card key={props.id}>
                {props.id === edit ? (
                  <>
                    <input defaultValue={props.task} />
                  </>
                ) : (
                  <>
                    <p>{props.task}</p>
                  </>
                )}
                <Describe key={props.id}>{props.description}</Describe>
                <Hold>
                  <ButtonHold>
                    <button
                      onClick={() => {
                        showEdit(props.id);
                      }}>
                      Edit
                    </button>
                    <button>Done</button>
                    <button
                      onClick={() => {
                        removeData(props.id);
                      }}>
                      Delete
                    </button>
                  </ButtonHold>
                </Hold>
              </Card>
            ))}
          </CardHold>
        </Wrapper>
      </Container>
    </div>
  );
};

export default TodoList;

const Container = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  background-color: #e7e5ee84;
`;
const InputField = styled.input`
  width: 380px;
  height: 50px;
  border-radius: 15px;
  margin-top: 15px;
  padding-left: 20px;
  border: none;
  outline: none;
  color: #353535;
  ::placeholder {
    color: #a7a6a6;
  }
`;
const Button = styled.button<{ bc: string; cs: string }>`
  background-color: ${(props) => props.bc};
  cursor: ${(props) => props.cs};
  width: 400px;
  margin-top: 15px;
  height: 50px;
  border-radius: 25px;
  border: none;
  color: white;
`;
const ImageBox = styled.div``;
const Card = styled.div`
  background-color: white;
  display: flex;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  /* align-items: center; */
  margin-bottom: 20px;
  flex-direction: column;
`;
const Hold = styled.div``;
const ButtonHold = styled.div`
  margin-top: 10px;
  button {
    width: 100px;
    height: 30px;
    margin-right: 10px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: cursive;
`;
const CardHold = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
const Describing = styled.div``;
const DescribeInput = styled.textarea`
  height: 50px;
  width: 400px;
  margin-top: 30px;
`;
const DescriptionButton = styled.button`
  width: 150px;
  height: 40px;
  border-radius: 20px;
  border: none;
  margin-top: 20px;
  color: white;
  background-color: #ff1940;
`;
const HoldDescribe = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Describe = styled.div`
  font-size: 13px;
`;
